<template>
  <PageCard
    pageName="All Profile Cards"
    :iconClass="['fa', 'fa-users', 'fa-lg']"
  >
    <template slot="page_content">
      <v-wait for="fetchingCards">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div class="row">
          <div class="col-sm-3 offset-sm-6 mb-3 text-right">
            <router-link
              tag="button"
              :to="{ path: '/super_admin/profile_cards/new' }"
              class="btn btn-primary fw-btn"
            >
              New Card
            </router-link>
          </div>
          <div class="col-sm-3 mb-3">
            <b-input-group>
              <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <b-form-input
                type="text"
                v-model="filter"
                placeholder="Search"
                ref="search"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-12">
            <b-table
              :items="profileCards"
              :fields="tableHeaders"
              :filter="filter"
              :show-empty="true"
              empty-text="No records found."
              responsive="md"
              striped
              hover
            >
              <template v-slot:cell(editCard)="data">
                <router-link
                  :to="{
                    name: 'SuperAdminEditCard',
                    params: { id: data.item.id }
                  }"
                >
                  Edit
                </router-link>
              </template>
              <template v-slot:cell(deleteCard)="data">
                <a
                  href="#"
                  @click="setSelectedCard(data.item)"
                  v-b-modal.deleteConfirmation
                  >Delete</a
                >
              </template>
            </b-table>
          </div>
        </div>

        <b-modal
          id="deleteConfirmation"
          ref="deleteConfirmation"
          title="Delete Card"
          @ok="deleteCardData"
          @cancel="resetSelectedCard"
        >
          <p>Are you sure, want to delete the Card?</p>
        </b-modal>
      </v-wait>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'ProfileCards',
  components: {
    PageCard
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.fetchAllCards()
  },
  data() {
    return {
      filter: null,
      profileCards: [],
      selectedCard: null
    }
  },
  computed: {
    totalRows() {
      return this.profileCards.length
    },
    tableHeaders() {
      return [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'parent_id', label: 'parent id', sortable: true },
        { key: 'card_row', label: 'Row', sortable: true },
        { key: 'card_column', label: 'Column', sortable: true },
        { key: 'card_size', label: 'Size', sortable: true },
        { key: 'enabled', label: 'Enabled', sortable: true },
        { key: 'editCard', label: '', sortable: false },
        { key: 'deleteCard', label: '', sortable: false }
      ]
    }
  },
  methods: {
    setSelectedCard(card) {
      this.selectedCard = card
    },
    resetSelectedCard() {
      this.selectedCard = null
    },
    fetchAllCards() {
      this.$wait.start('fetchingCards')
      this.$http
        .get('/api/super_admin/profile_cards', {
          handleErrors: true,
          params: {
            super_admin: true
          }
        })
        .then(res => {
          this.profileCards = res.data
        })
        .then(() => {
          this.$wait.end('fetchingCards')
        })
    },
    deleteCardData(evt) {
      evt.preventDefault()
      let vm = this
      this.$http
        .delete('/api/super_admin/profile_cards/' + this.selectedCard.id, {
          handleErrors: true
        })
        .then(res => {
          vm.$refs.deleteConfirmation.hide()
          vm.profileCards = vm.profileCards.filter(
            card => card.id != vm.selectedCard.id
          )
          vm.setSelectedCard({})
          Vue.toasted.show(res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
